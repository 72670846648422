<template>
    <modal
        id="rating"
        ref="rating"
        :title="$t('modal.rate.heading')"
        type="product"
    >
        <form
            id="rate"
            @submit.prevent="rate"
        >
            <div
                class="form-group w100"
                :class="{ error: errors.rating }"
            >
                <label>{{ $t('form.fields.label.rating') }}</label>
                <input
                    v-model="input.rating"
                    type="number"
                    min="1"
                    max="5"
                    class="form-control hide"
                    name="rating"
                >
                <div class="stars">
                    <star-icon
                        v-for="index in 5"
                        :key="index"
                        :class="{ selected: index <= input.rating }"
                        @click="input.rating = index"
                    />
                </div>
                <span
                    v-if="errors.rating"
                    class="error-message"
                >{{
                    errors.rating
                }}</span>
            </div>
            <div
                class="form-group w100"
                :class="{ error: errors.message || errors.productId }"
            >
                <label>{{ $t('form.fields.text.rating') }} - {{ input.message.length }} /
                    1000</label>
                <textarea
                    v-model="input.message"
                    :placeholder="$t('form.fields.placeholder.rating')"
                    class="form-control"
                    name="message"
                    rows="4"
                />
                <span
                    v-if="errors.message"
                    class="error-message"
                >{{
                    errors.message
                }}</span>
                <span
                    v-if="errors.productId"
                    class="error-message"
                >{{
                    errors.productId
                }}</span>
            </div>
            <caption>
                {{ $t('modal.rate.info') }} {{ isEdit ? $t('modal.rate.editInfo') : '' }}
            </caption>
            <div class="actions">
                <b-button
                    variant="primary"
                    class="stroke login"
                    @click="closeModal()"
                >
                    {{ $t('modal.rate.cancelButton') }}
                </b-button>
                <b-button
                    :disabled="sending"
                    variant="primary"
                    class="fill login"
                    type="submit"
                >
                    {{ $t('modal.rate.sendButton') }}
                </b-button>
            </div>
        </form>
    </modal>
</template>

<script>
    import StarIcon from 'vue-feather-icons/icons/StarIcon';
    import { mapActions, mapMutations, mapState } from 'vuex';

    import { formValidations } from '@/services/formValidations';
    import { inputErrors } from '@/services/inputErrors';

    import Modal from './Modal.vue';
    export default {
        name: 'RatingModal',
        components: {
            Modal,
            StarIcon,
        },
        props: {
            rating: {
                type: Number,
                default: 5,
            },
            productId: {
                type: Number,
                default: 0,
            },
            message: {
                type: String,
                default: '',
            },
            isEdit: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                input: {
                    rating: null,
                    productId: null,
                    message: '',
                },
                errors: {},
                sending: false,
            };
        },
        computed: {
            ...mapState('product', ['inputErrors', 'product']),
        },
        methods: {
            ...mapActions('product', ['addRating']),
            ...mapMutations('product', ['setModal']),
            async rate() {
                this.errors = formValidations(this.input, 'rating');
                if (Object.keys(this.errors).length !== 0) {
                    return;
                }
                this.sending = true;
                await this.addRating(this.input);
                this.sending = false;

                if (Object.keys(this.inputErrors).length > 0) {
                    this.errors = Object.assign({}, inputErrors(this.inputErrors));
                    return;
                }
                this.$toast.open({
                    message: this.$t('notification.productRatingAdded'),
                    type: 'success',
                    duration: 10000,
                });
                this.closeModal();
            },
            reset() {
                this.input.message = this.message;
                this.input.rating = this.rating;
            },
            closeModal() {
                this.reset();
                this.$refs.rating.closeModal();
            },
        },
        mounted() {
            this.input.productId = this.productId ? this.productId : null;
            this.input.rating = this.rating ? this.rating : 5;
            this.input.message =  this.message ? this.message : '';
        },
    };
</script>
<style scoped lang="scss">
.stars {
  svg {
    stroke: $gray;
    &.selected {
      stroke: $secondary;
    }
  }
  @include media-breakpoint-up(md) {
    &:hover {
      > svg {
        stroke: $secondary;
        cursor: pointer;
        &:hover ~ svg {
          stroke: $gray;
        }
      }
    }
  }
}
</style>
